.ql-editor {
  height: 30vh !important; 
  font-size: 16px;
  background-color: #fff;
  padding-top: 10px;
  border-radius: 0px 0px 15px 15px  !important;
  font-family: Montserrat, sans-serif;
}

 .quill {
  text-align: center !important;
}
 .ql-editor li:not(.ql-direction-rtl)::before {
  margin-right:  0.5em !important;
  margin-left: 0.3em !important;
  text-align: right !important;
}

.ql-editor.ql-snow {
  border-radius: 15px !important;
}

:root div[dir="rtl"] .ql-editor {
  direction: rtl;
  text-align: right;
  font-family: DINNextLTW23Light, sans-serif;
}

.ql-toolbar {
  background-color: #fbfbfb;
}

.ql-container.ql-snow {
  border: 1px solid #E8E8E8 !important;
  border-radius: 0px 0px 12px 12px  !important;
}

.ql-toolbar.ql-snow {          
   border-radius: 12px 12px 0px 0px !important;
   border: 1px solid #E8E8E8 !important;
   padding-top: 15px;
   padding-bottom: 15px;
}

